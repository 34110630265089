
.AutocompleteContainer > div{
  width: 100%;
}

.acinput{
  width: 100%;
  padding: 8.5px 14px;
}

.autoDropdownlist{
  padding: 10px;
}

