.logo {
  width: 64px;
}

.main-entry {
  padding: 16px;
  max-width: 1366px;
  margin: 0 auto;
}

.padding-16 {
  padding: 16px !important;
}

.white {
  color: #fff !important;
}

.queue-table {
  table-layout: auto;
}

.sort-label{
  text-transform: capitalize !important;
}
.transaction-table {
  table-layout: auto;
}

.transaction-table td button {
  padding: 0;
  margin: 0;
  min-height: 10px;
}

.transaction-table td,
.transaction-table th {
  padding: 4px;
}

.transactions-table td a {
  margin: 0 4px;
}

.DayPickerInput {
  width: 100%;
}

.DayPickerInput input {
  width: 100%;
  padding: 8px;
}


.AutocompleteContainer > div{
  width: 100%;
}

.acinput{
  width: 100%;
  padding: 8.5px 14px;
}

.autoDropdownlist{
  padding: 10px;
}



.queue-table td button {
  padding: 0;
  margin: 0;
  min-height: 10px;
}

.queue-table td,
.queue-table th {
  padding: 4px;
}

.queue-table td a {
  margin: 0 4px;
}

#flight-queue-popper > div:nth-child(2) button {
  display: block;
  width: 100%;
  border-bottom: 1px solid #e5e2e2;
  border-radius: 0;
}

.search-field {
  float: right;
}

#hotell-queue-popper > div:nth-child(2) button {
  display: block;
  width: 100%;
  border-bottom: 1px solid #e5e2e2;
  border-radius: 0;
}

.hotel-booking-queues-table {
  width: 100%;
  overflow: scroll;
}
.tg-date-label {
    font-size: 12px;
    background: #fff;
    position: absolute;
    top: -7px;
    left: 8px;
    padding: 0 8px;
    color: #757575;
  }
  
  .tg-date-input .DayPickerInput {
    width: 100%;
  }
  
  .tg-date-input .DayPickerInput input {
    padding: 18px !important;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    width: 100%;
  }
  
  .tg-date-input .DayPickerInput-Overlay {
    z-index: 9;
  }
  
.ticket-logo {
  width: 125px;
  background: #0c385e;
  padding: 6px;
}

.ticket-flight-details {
  padding: 20px 0;
}
.text-center {
  text-align: center;
}

svg.rotate-90 {
  vertical-align: bottom;
}
.rotate-90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.full-width {
    width: 100%;
}
.hotel-ticket {
  padding: 16px;
}
.bold {
  font-weight: bold !important;
}

@media print {
  body {
     -webkit-print-color-adjust: exact;
  }
}

.creds-drawer > div:nth-child(2) {
  width: 50%;
  padding: 16px;
}

.add-supplier > div:nth-child(2),
.register-drawer > div:nth-child(2) {
  width: 45%;
  padding: 16px;
}

.cred-key input,
.cred-password input {
  padding: 12.5px 6px;
  height: 24px;
}

.error-text {
  color: red !important;
}

@media screen and (max-width: 980px) {
  .add-supplier > div:nth-child(2),
  .creds-drawer > div:nth-child(2),
  .register-drawer > div:nth-child(2) {
    width: 100%;
  }
}

.auth-paper {
  width: 400px;
  margin: 20px auto;
}

.cabs-drawer > div:nth-child(2),
.ct-select-drawer > div:nth-child(2) {
  width: 45%;
  padding: 16px;
}

kbd {
  background-color: #222;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
}

#cab_image_preview {
  max-width: 317px;
}

.areas-covered > div {
  min-height: 54px;
}

.areas-covered > div:nth-child(3) {
  z-index: 9;
}

.top-right {
  position: absolute !important;
  right: 16px;
  top: 16px;
}

.invalid-text {
  color: red !important;
}

@media screen and (max-width: 980px) {
  .cabs-drawer > div:nth-child(2),
  .ct-select-drawer > div:nth-child(2) {
    width: 100%;
  }
}

.add-card-drawer > div:nth-child(2) {
  width: 100%;
  padding: 16px;
}
.custom-input {
  margin: 12px 0 !important;
}

.custom-input input {
  padding: 10px 12px !important;
}

.custom-input > div {
  height: 36px !important;
}
.generate-btn {
  margin-top: 12px !important;
}

.active-menu {
  background-color: rgba(0, 0, 0, 0.08) !important;
}

.right {
  float: right;
}

.requests-table {
  table-layout: auto;
}

.requests-table td button {
  padding: 0;
  margin: 0;
  min-height: 10px;
}

.requests-table td,
.requests-table th {
  padding: 4px;
}

.requests-table .approve-btn {
  margin: 0 4px;
  text-decoration: none;
  color: #e91e63;
  font-weight: bold;
  border: 1px solid;
  padding: 5px;
  width: 115px;
  cursor: pointer;
}

.approve-request-drawer > div:nth-child(2) {
  width: 45%;
  padding: 16px;
}

.view-card-drawer > div:nth-child(2) {
  width: 45%;
  padding: 16px;
}

.view-details-link {
  margin: 0 4px;
  text-decoration: none;
  color: #e91e63 !important;
  font-weight: bold !important;
  border: 1px solid #ddd;
  padding: 5px;
  width: 50px;
  cursor: pointer;
  text-align: center;
}

.card-design {
  border: 1px solid #e1bee7;
  padding: 16px;
  border-radius: 5px;
  width: 80%;
  margin: 20px auto;
  position: relative;
}

.card-provider {
  position: absolute;
  right: 16px;
  top: 8px;
  font-size: 18px !important;
  color: #4caf50 !important;
  font-weight: 500 !important;
  text-transform: capitalize;
}

.card-divider {
  border-top: 1px solid #ddd;
  padding: 16px;
  margin: 16px 0;
}

.card-balance {
  font-size: 22px !important;
  text-align: center;
}

.active {
  color: #009688 !important;
  font-weight: 500 !important;
  text-transform: capitalize;
}

.d-flex {
  display: flex;
}

.d-flex > div {
  margin-top: 24px;
  width: 25%;
}

.component-error-text {
  color: red !important;
}

.mt-10{
  margin-top: 10px;
}
.tg-select-label {
  position: absolute;
  top: -7px;
  z-index: 9;
  background-color: #fff;
  left: 12px;
  padding: 0 6px;
  color: #7e7e7e;
  font-size: 12px;
}

.tg-select .Select-control {
  padding: 9.5px;
}

.tg-select .Select-menu-outer {
  z-index: 9;
}

.tg-select .Select-value,
.tg-select .Select-placeholder {
  margin-top: 10px;
}

.agent-contact-info-form input,
.cab-details-form input,
.customer-details-form input,
.farebreakup-form input,
.visa-details-form input,
.hotel-details-form input,
.flight-fare-breakup input,
.flight-card-form input,
.passenger-details-form input,
.select > div > div > div {
  padding: 12px 14px;
}

.total-update-btn {
  margin-top: -30px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mt-8{
  margin-top: 8px !important;
}
.scan-item {
  padding: 16px;
  height: 100%;
  position: relative;
}

.scan-item img {
  width: 100%;
}

.edit-button {
  position: absolute !important;
  top: 16px;
  right: 16px;
}

.edit-expense-drawer > div:nth-child(2) {
  width: 45%;
  padding: 16px;
}

.expense-upload-container {
  height: 300px;
}

.expense-upload-container img {
  width: 100%;
}

@media screen and (max-width: 599px) {
  .edit-expense-drawer > div:nth-child(2) {
    width: 100%;
  }
}

.cursor {
  cursor: pointer;
}
.queue-table td button {
  padding: 0;
  margin: 0;
  min-height: 10px;
}

.queue-table td,
.queue-table th {
  padding: 4px;
}

.queue-table td a {
  margin: 0 4px;
}

#flight-queue-popper > div:nth-child(2) button {
  display: block;
  width: 100%;
  border-bottom: 1px solid #e5e2e2;
  border-radius: 0;
}

.search-field {
  float: right;
}



.train-info {
  display: flex;
  justify-content: space-around;
}

.train-info {
  display: flex;
  justify-content: space-around;
}

.train-info {
  display: flex;
  justify-content: space-around;
}

.creds-drawer > div:nth-child(2) {
  width: 50%;
  padding: 16px;
}

.add-promo > div:nth-child(2),
.custom-drawer > div:nth-child(2),
.register-drawer > div:nth-child(2) {
  width: 45%;
  padding: 16px;
}

.review-drawer > div:nth-child(2) {
  width: 60%;
  padding: 16px;
}

.cred-key input,
.cred-password input {
  padding: 12.5px 6px;
  height: 24px;
}

.error-text {
  color: red !important;
}

@media screen and (max-width: 980px) {
  .add-promo > div:nth-child(2),
  .custom-drawer > div:nth-child(2),
  .creds-drawer > div:nth-child(2),
  .register-drawer > div:nth-child(2),
  .review-drawer > div:nth-child(2) {
    width: 100%;
  }
}

.creds-drawer > div:nth-child(2) {
  width: 50%;
  padding: 16px;
}

.add-promo > div:nth-child(2),
.custom-drawer > div:nth-child(2),
.register-drawer > div:nth-child(2) {
  width: 45%;
  padding: 16px;
}

.review-drawer > div:nth-child(2) {
  width: 60%;
  padding: 16px;
}

.cred-key input,
.cred-password input {
  padding: 12.5px 6px;
  height: 24px;
}

.error-text {
  color: red !important;
}

@media screen and (max-width: 980px) {
  .add-promo > div:nth-child(2),
  .custom-drawer > div:nth-child(2),
  .creds-drawer > div:nth-child(2),
  .register-drawer > div:nth-child(2),
  .review-drawer > div:nth-child(2) {
    width: 100%;
  }
}

.service-title {
  padding: 12px;
  background-color: #f5f5f5;
  text-transform: uppercase;
}

.trip-name {
  padding: 16px 12px;
  cursor: pointer;
}

.active-trip {
  background-color: #ddd;
}

.active-trip h6 {
  font-weight: bold;
}

.fs-16 {
  font-size: 16px !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.relative {
  position: relative;
}

.table-auto {
  table-layout: auto;
}

.table-auto td button {
  padding: 0;
  margin: 0;
  min-height: 10px;
}

.table-auto td,
.tabel-auto th {
  padding: 4px;
}

.table-auto td a {
  margin: 0 4px;
}

.nav-link {
  text-decoration: none;
}

.nav-link button {
  color: #fff !important;
}

.right {
  float: right;
}

.flt-img {
  margin: 0 auto;
  vertical-align: middle;
}

.change-over-msg {
  background: #fff59d;
  padding: 8px;
  border-radius: 4px;
}

.only-print {
  display: none;
}

.menu-buttons {
  padding-top: 10px;
}

.blue-btn {
  color: #1976d2 !important;
  font-weight: bold !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.caption {
  color: rgba(0, 0, 0, 0.54) !important;
}

.ticket-email-dialog > div:nth-child(2) {
  width: 400px;
}

.capitalize{
  text-transform: capitalize;
}

.download-report-dialog > div:nth-child(2),
.train-cancel-drawer > div:nth-child(2),
.upload-excel-drawer > div:nth-child(2) {
  width: 45%;
  padding: 16px;
}

.download-report-dialog > div:nth-child(2),
.train-cancel-drawer > div:nth-child(2),
.upload-excel-drawer > div:nth-child(2) {
  width: 45%;
  padding: 16px;
}

.full-width-drawer > div:nth-child(2) {
  width: 100%;
  padding: 16px;
}

.trip-items-lite-drawer > div:nth-child(2),
.ticket-resc-drawer > div:nth-child(2),
.pending-approval-log-drawer > div:nth-child(2),
.map-traveller-drawer > div:nth-child(2),
.chart-expander-drawer > div:nth-child(2) {
  width: 65%;
  padding: 16px;
}

.red-text-container, .red-text {
  display: inline-block !important;
}
.red-text {
  color: red !important;
  text-transform: capitalize;
}

@media screen and (max-width: 599px) {
  .main-entry {
    width: 100%;
    padding: 8px;
  }
  .sidebar > div:nth-child(2) {
    width: 200px;
  }
  .auth-paper {
    width: 100%;
  }
  .ticket-email-dialog > div:nth-child(2) {
    min-width: 100%;
  }
  .xs-100 > div:nth-child(2) {
    min-width: 100%;
  }
}

@media print {
  @page {
    margin: 0;
  }
  body {
    margin: 1em;
    font-size: 0.8em;
  }
  header {
    display: none !important;
  }
  .ticket-logo {
    background-color: #0c385f !important;
  }
  .print-btn {
    display: none;
  }
  .print-al {
    width: 33%;
    flex-basis: unset !important;
  }
  .no-print {
    display: none;
  }
  .only-print {
    display: block;
  }
}

.smart-scan-img {
  width: 24px;
  height: 24px;
}

table.flight-canx-pax-table tr th,
table.flight-canx-pax-table tr td {
  padding: 8px;
}
