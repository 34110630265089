.hotel-ticket {
  padding: 16px;
}
.bold {
  font-weight: bold !important;
}

@media print {
  body {
     -webkit-print-color-adjust: exact;
  }
}