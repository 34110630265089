.tg-date-label {
    font-size: 12px;
    background: #fff;
    position: absolute;
    top: -7px;
    left: 8px;
    padding: 0 8px;
    color: #757575;
  }
  
  .tg-date-input .DayPickerInput {
    width: 100%;
  }
  
  .tg-date-input .DayPickerInput input {
    padding: 18px !important;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    width: 100%;
  }
  
  .tg-date-input .DayPickerInput-Overlay {
    z-index: 9;
  }
  