.agent-contact-info-form input,
.cab-details-form input,
.customer-details-form input,
.farebreakup-form input,
.visa-details-form input,
.hotel-details-form input,
.flight-fare-breakup input,
.flight-card-form input,
.passenger-details-form input,
.select > div > div > div {
  padding: 12px 14px;
}

.total-update-btn {
  margin-top: -30px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mt-8{
  margin-top: 8px !important;
}