.add-card-drawer > div:nth-child(2) {
  width: 100%;
  padding: 16px;
}
.custom-input {
  margin: 12px 0 !important;
}

.custom-input input {
  padding: 10px 12px !important;
}

.custom-input > div {
  height: 36px !important;
}
.generate-btn {
  margin-top: 12px !important;
}

.active-menu {
  background-color: rgba(0, 0, 0, 0.08) !important;
}

.right {
  float: right;
}

.requests-table {
  table-layout: auto;
}

.requests-table td button {
  padding: 0;
  margin: 0;
  min-height: 10px;
}

.requests-table td,
.requests-table th {
  padding: 4px;
}

.requests-table .approve-btn {
  margin: 0 4px;
  text-decoration: none;
  color: #e91e63;
  font-weight: bold;
  border: 1px solid;
  padding: 5px;
  width: 115px;
  cursor: pointer;
}

.approve-request-drawer > div:nth-child(2) {
  width: 45%;
  padding: 16px;
}

.view-card-drawer > div:nth-child(2) {
  width: 45%;
  padding: 16px;
}

.view-details-link {
  margin: 0 4px;
  text-decoration: none;
  color: #e91e63 !important;
  font-weight: bold !important;
  border: 1px solid #ddd;
  padding: 5px;
  width: 50px;
  cursor: pointer;
  text-align: center;
}

.card-design {
  border: 1px solid #e1bee7;
  padding: 16px;
  border-radius: 5px;
  width: 80%;
  margin: 20px auto;
  position: relative;
}

.card-provider {
  position: absolute;
  right: 16px;
  top: 8px;
  font-size: 18px !important;
  color: #4caf50 !important;
  font-weight: 500 !important;
  text-transform: capitalize;
}

.card-divider {
  border-top: 1px solid #ddd;
  padding: 16px;
  margin: 16px 0;
}

.card-balance {
  font-size: 22px !important;
  text-align: center;
}

.active {
  color: #009688 !important;
  font-weight: 500 !important;
  text-transform: capitalize;
}

.d-flex {
  display: flex;
}

.d-flex > div {
  margin-top: 24px;
  width: 25%;
}

.component-error-text {
  color: red !important;
}

.mt-10{
  margin-top: 10px;
}