.service-title {
  padding: 12px;
  background-color: #f5f5f5;
  text-transform: uppercase;
}

.trip-name {
  padding: 16px 12px;
  cursor: pointer;
}

.active-trip {
  background-color: #ddd;
}

.active-trip h6 {
  font-weight: bold;
}

.fs-16 {
  font-size: 16px !important;
}
