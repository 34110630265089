body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.relative {
  position: relative;
}

.table-auto {
  table-layout: auto;
}

.table-auto td button {
  padding: 0;
  margin: 0;
  min-height: 10px;
}

.table-auto td,
.tabel-auto th {
  padding: 4px;
}

.table-auto td a {
  margin: 0 4px;
}

.nav-link {
  text-decoration: none;
}

.nav-link button {
  color: #fff !important;
}

.right {
  float: right;
}

.flt-img {
  margin: 0 auto;
  vertical-align: middle;
}

.change-over-msg {
  background: #fff59d;
  padding: 8px;
  border-radius: 4px;
}

.only-print {
  display: none;
}

.menu-buttons {
  padding-top: 10px;
}

.blue-btn {
  color: #1976d2 !important;
  font-weight: bold !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.caption {
  color: rgba(0, 0, 0, 0.54) !important;
}

.ticket-email-dialog > div:nth-child(2) {
  width: 400px;
}

.capitalize{
  text-transform: capitalize;
}

.download-report-dialog > div:nth-child(2),
.train-cancel-drawer > div:nth-child(2),
.upload-excel-drawer > div:nth-child(2) {
  width: 45%;
  padding: 16px;
}

.download-report-dialog > div:nth-child(2),
.train-cancel-drawer > div:nth-child(2),
.upload-excel-drawer > div:nth-child(2) {
  width: 45%;
  padding: 16px;
}

.full-width-drawer > div:nth-child(2) {
  width: 100%;
  padding: 16px;
}

.trip-items-lite-drawer > div:nth-child(2),
.ticket-resc-drawer > div:nth-child(2),
.pending-approval-log-drawer > div:nth-child(2),
.map-traveller-drawer > div:nth-child(2),
.chart-expander-drawer > div:nth-child(2) {
  width: 65%;
  padding: 16px;
}

.red-text-container, .red-text {
  display: inline-block !important;
}
.red-text {
  color: red !important;
  text-transform: capitalize;
}

@media screen and (max-width: 599px) {
  .main-entry {
    width: 100%;
    padding: 8px;
  }
  .sidebar > div:nth-child(2) {
    width: 200px;
  }
  .auth-paper {
    width: 100%;
  }
  .ticket-email-dialog > div:nth-child(2) {
    min-width: 100%;
  }
  .xs-100 > div:nth-child(2) {
    min-width: 100%;
  }
}

@media print {
  @page {
    margin: 0;
  }
  body {
    margin: 1em;
    font-size: 0.8em;
  }
  header {
    display: none !important;
  }
  .ticket-logo {
    background-color: #0c385f !important;
  }
  .print-btn {
    display: none;
  }
  .print-al {
    width: 33%;
    flex-basis: unset !important;
  }
  .no-print {
    display: none;
  }
  .only-print {
    display: block;
  }
}

.smart-scan-img {
  width: 24px;
  height: 24px;
}

table.flight-canx-pax-table tr th,
table.flight-canx-pax-table tr td {
  padding: 8px;
}