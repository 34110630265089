.tg-select-label {
  position: absolute;
  top: -7px;
  z-index: 9;
  background-color: #fff;
  left: 12px;
  padding: 0 6px;
  color: #7e7e7e;
  font-size: 12px;
}

.tg-select .Select-control {
  padding: 9.5px;
}

.tg-select .Select-menu-outer {
  z-index: 9;
}

.tg-select .Select-value,
.tg-select .Select-placeholder {
  margin-top: 10px;
}
