.cabs-drawer > div:nth-child(2),
.ct-select-drawer > div:nth-child(2) {
  width: 45%;
  padding: 16px;
}

kbd {
  background-color: #222;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
}

#cab_image_preview {
  max-width: 317px;
}

.areas-covered > div {
  min-height: 54px;
}

.areas-covered > div:nth-child(3) {
  z-index: 9;
}

.top-right {
  position: absolute !important;
  right: 16px;
  top: 16px;
}

.invalid-text {
  color: red !important;
}

@media screen and (max-width: 980px) {
  .cabs-drawer > div:nth-child(2),
  .ct-select-drawer > div:nth-child(2) {
    width: 100%;
  }
}
