.creds-drawer > div:nth-child(2) {
  width: 50%;
  padding: 16px;
}

.add-promo > div:nth-child(2),
.custom-drawer > div:nth-child(2),
.register-drawer > div:nth-child(2) {
  width: 45%;
  padding: 16px;
}

.review-drawer > div:nth-child(2) {
  width: 60%;
  padding: 16px;
}

.cred-key input,
.cred-password input {
  padding: 12.5px 6px;
  height: 24px;
}

.error-text {
  color: red !important;
}

@media screen and (max-width: 980px) {
  .add-promo > div:nth-child(2),
  .custom-drawer > div:nth-child(2),
  .creds-drawer > div:nth-child(2),
  .register-drawer > div:nth-child(2),
  .review-drawer > div:nth-child(2) {
    width: 100%;
  }
}
