.scan-item {
  padding: 16px;
  height: 100%;
  position: relative;
}

.scan-item img {
  width: 100%;
}

.edit-button {
  position: absolute !important;
  top: 16px;
  right: 16px;
}

.edit-expense-drawer > div:nth-child(2) {
  width: 45%;
  padding: 16px;
}

.expense-upload-container {
  height: 300px;
}

.expense-upload-container img {
  width: 100%;
}

@media screen and (max-width: 599px) {
  .edit-expense-drawer > div:nth-child(2) {
    width: 100%;
  }
}

.cursor {
  cursor: pointer;
}