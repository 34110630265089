.transaction-table {
  table-layout: auto;
}

.transaction-table td button {
  padding: 0;
  margin: 0;
  min-height: 10px;
}

.transaction-table td,
.transaction-table th {
  padding: 4px;
}

.transactions-table td a {
  margin: 0 4px;
}

.DayPickerInput {
  width: 100%;
}

.DayPickerInput input {
  width: 100%;
  padding: 8px;
}
