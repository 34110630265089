.ticket-logo {
  width: 125px;
  background: #0c385e;
  padding: 6px;
}

.ticket-flight-details {
  padding: 20px 0;
}
.text-center {
  text-align: center;
}

svg.rotate-90 {
  vertical-align: bottom;
}
.rotate-90 {
  transform: rotate(90deg);
}

.full-width {
    width: 100%;
}