.queue-table td button {
  padding: 0;
  margin: 0;
  min-height: 10px;
}

.queue-table td,
.queue-table th {
  padding: 4px;
}

.queue-table td a {
  margin: 0 4px;
}

#flight-queue-popper > div:nth-child(2) button {
  display: block;
  width: 100%;
  border-bottom: 1px solid #e5e2e2;
  border-radius: 0;
}

.search-field {
  float: right;
}
