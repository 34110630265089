#hotell-queue-popper > div:nth-child(2) button {
  display: block;
  width: 100%;
  border-bottom: 1px solid #e5e2e2;
  border-radius: 0;
}

.hotel-booking-queues-table {
  width: 100%;
  overflow: scroll;
}