.logo {
  width: 64px;
}

.main-entry {
  padding: 16px;
  max-width: 1366px;
  margin: 0 auto;
}

.padding-16 {
  padding: 16px !important;
}

.white {
  color: #fff !important;
}

.queue-table {
  table-layout: auto;
}

.sort-label{
  text-transform: capitalize !important;
}